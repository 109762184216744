import React, {Suspense, useEffect} from 'react';
import './App.css';
import "./UI/theme/theme.css";
import "./UI/theme/typography.css"
import "./UI/theme/form.css"
import "./revol.css";
import "./shazam.css";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { SITE_MAP } from './utils/constants';

if(process.env.NODE_ENV === 'development') {
  (window as any).minibzapp = {
    routes: SITE_MAP,
    userAuthToken: {
      pizzeria: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjMsInFyY29kZSI6Ik1CWi1HNjNIUzMiLCJpYXQiOjE3MTc5NjU3MDksImV4cCI6MTcxOTY5MzcwOX0.uA94VNrmuW6e1vb2eMkFowO3qJiOqc7dtaH4UQ8taC8',
      ufficcioLavoro: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjIsInFyY29kZSI6Ik1CWi03SFI5M0YiLCJpYXQiOjE3MTc5NjU2NzQsImV4cCI6MTcxOTY5MzY3NH0.lyB2ocPEFR2K4t7VyBuk46zJtvOqX3q8ujznOlrZVZk'
    },
    userData: {
      "id": 3,
      "name": "Pizzeria",
      "name_deutsch": "Pizzeria",
      "type": "SECTOR",
      "gender": "M",
      "age": 0,
      "avatar": "Pizzeria.png",
      "qrcode": "MBZ-G63HS3",
      "qrcode_url": "",
      "wallet_balance": 2390.45,
      "last_transactions": [],
      "permissions": ['BANK', 'UFFICIO_LAVORO', 'POST_CREATE', 'CREATE_EDIT_USER_USER', 'SEARCH_USER_FROM_JOBS_HISTORY']
    }
  };
}

(window as any).minibzapp['routes'] = SITE_MAP;

const PAGE =  {
  Login: React.lazy(() => import(/* webpackChunkName: "login" */'./pages/LoginPage')),
  Posts: React.lazy(() => import(/* webpackChunkName: "home" */ './pages/PostsPage')),
  Home: React.lazy(() => import(/* webpackChunkName: "home" */ './pages/HomePage')),
  Account: React.lazy(() => import(/* webpackChunkName: "wallet" */'./pages/AccountPage')),
  AccountTransactions: React.lazy(() => import('./pages/AccountTransactionsPage')),
  UserTransactions: React.lazy(() => import('./pages/TransactionsPage')),
  Dashboard: React.lazy(() => import(/* webpackChunkName: "dashboard" */'./pages/DashboardPage')),
  UfficioLavoro: React.lazy(() => import(/* webpackChunkName: "ufficcio-lavoro" */'./pages/UfficioLavoroPage')),
  BankUserWaller: React.lazy(()=> import(/* webpackChunkName: "user-wallet" */ './pages/BankUserWalletPage')),
  JobHire: React.lazy(() => import(/* webpackChunkName: "job-hire" */'./pages/JobhHirePage')),
  SectorJobs: React.lazy(() => import('./pages/SectorJobsPage')),
}

function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<div style={{width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <div className="minibz-app-loader">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div></div>}>
        <Routes>
          <Route path={'/'} index element={<PAGE.Posts/>}/>
          <Route path={'/login'} element={<PAGE.Login />} />
          <Route path={'/account'} element={<PAGE.Account active={'WALLET'} />} />
          <Route path={'/account/employers'} element={<PAGE.Account active={'EMPLOYERS'} />} />
          <Route path={'/account/jobs'} element={<PAGE.Account active={'JOBS'} />} />
          <Route path={'/account/ufficio-lavoro'} element={<PAGE.Account active={'UFFICCIO-LAVORO'} />} />
          <Route path={'/account/search'} element={<PAGE.Account active={'SEARCH_USER_FROM_JOBS_HISTORY'} />} />
          <Route path={'/account/bank/users'} element={<PAGE.Account active={'BANK'} />} />
          <Route path={'/bank/user/:userId/wallet'} element={<PAGE.BankUserWaller />} />
          <Route path={'/transactions'} element={<PAGE.AccountTransactions />} />
          <Route path={'/user/:userId/transactions'} element={<PAGE.UserTransactions />} />
          <Route path={SITE_MAP.login} element={<PAGE.Login />} />
          <Route path={SITE_MAP.dashboard} element={<PAGE.Dashboard />} />
          <Route path={SITE_MAP.ufficcioLavoro} element={<PAGE.UfficioLavoro />} />
          <Route path={'/job/hire'} element={<PAGE.JobHire />}  />
          <Route path={'/sector/:sectorId/jobs'} element={<PAGE.SectorJobs />} />
        </Routes>
        </Suspense>
    </BrowserRouter>
  );
}

export default App;
