export const MINIBZAPP_SERVER_URL = (function(){
    if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        return 'http://localhost:8067';
    }

    return window.location.origin;
})()

export const SITE_MAP = {
    login: '/login',
    logout: '/logout',
    dashboard: '/dashboard',
    ufficcioLavoro: '/ufficcio-lavoro',
    jobs: '/jobs',
}
